<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Contact Profile" icon="address-card" />

    <div class="mainBox mainBox55 border shadow-sm">
        <div class="row">
            <div class="col-12 fw-bold pb-2">
                <table width="100%">
                    <tr>
                        <td>
                            <Popper class="popperDark" arrow hover content="Back">
                                <fa icon="angle-left" v-if="!showEditMode" size="lg" class="me-3 isLink" @click="$router.push({ path: '/contact/list' })" />
                            </Popper>
                            Personal Information
                        </td>
                        <td class="text-end">
                            <!-- Small screen -->
                            <span v-if="!showEditMode" @click="showEditMode = !showEditMode" class="text-end d-block d-md-none text-grey isLink me-3">
                                <fa icon="pen" size="lg" />
                            </span>
                            <!-- End Small screen -->
                        </td>
                        <td class="text-end">
                            <span v-if="!showEditMode">
                                <a href="#" data-bs-toggle="dropdown">
                                    <Popper class="popperDark" arrow hover content="More actions">
                                        <fa icon="ellipsis-vertical" class="px-2 isLink" style="font-size: 18px" />
                                    </Popper>
                                </a>

                                <ul class="dropdown-menu dropdown-menu-secondary">
                                    <li>
                                        <a class="dropdown-item" href="#">Delete contact<span class="float-end"><fa icon="trash" /></span></a>
                                    </li>
                                    
                                </ul>
                            </span> 
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-12 pb-3 text-center">
                <table border="0" width="100%">
                    <tr>
                        <td width="140px">
                            <div class="d-flex justify-content-center text-center" style="width: 120px; height: 120px">
                                <img v-if="user.avatar" :src="user.avatar" class="rounded-circle" align="center" style="position:absolute; width: 120px; height: 120px; object-fit: cover" /> 
                                <fa v-else icon="circle-user" style="position:absolute; width: 120px; height: 120px;" />
                                <div v-if="showEditMode" class="customLink rounded-circle isLink" data-bs-toggle="modal" data-bs-target="#mdlAvatar">
                                    <fa icon="camera" class="text-secondary" style="font-size: 32px;"/>
                                </div>
                                <span v-if="user.isVerified === 'true'" id="certificate" style="z-index:1000; font-size: 25px; margin-top: 82px; margin-left: 98px; position:absolute;">
                                    <fa icon="certificate" style="color: #53BDEB" />
                                    <div id="lblCertificate">Verified</div>
                                </span>    

                            </div>
                        </td>
                        <td class="text-start">
                            <div style="font-size: 16px">{{user.nickname}}</div>
                            <div>{{user.userId}}</div>
                            <div v-if="!showEditMode">
                                <!-- <span class="badge tag isLabel" v-for="t in user.tags" :key="t">
                                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                                    <span class="tagClose" @click.prevent="delTag(proj.projectId, proj.tags, t)">
                                        <fa icon="xmark" />
                                    </span>
                                </span> -->

                                <span class="ms-1">
                                    <div v-if="!showDropdown" class="badge tag" v-for="(t, tIdx) in user.tags" :key="t">
                                        <fa icon="hashtag" class="pe-1"/>{{ t }}
                                        <!-- Small screen -->
                                        <span class="d-lg-none">
                                            <a href="#" class="ms-1" @click="delTag(t, tIdx)"><fa icon="xmark" /></a>
                                        </span>
                                        <!-- Big screen -->
                                        <span class="tagClose" @click="delTag(t, tIdx)"><fa icon="xmark" /></span>
                                    </div>
                                    
                                    <div class="badge btnTag isLink text-white" @click="showDropdown = !showDropdown">
                                        <span v-if="showDropdown">
                                            <Popper class="popperDark" arrow hover content="Close add tag">
                                            <fa icon="minus" />
                                            </Popper>
                                        </span>
                                        <span v-else>
                                            <Popper class="popperDark" arrow hover content="Add tag">
                                            <fa icon="plus" />
                                            </Popper>
                                        </span>
                                    </div>

                                    <!-- Tag -->
                                    <div v-if="showDropdown" class="custom-dropdown">
                                        <Tag :tags="user.tags" @delTag="delTag" />
                                        <input type="text" v-model="userTag" ref="userTagRef" class="inputTag my-2" autocomplete="off" 
                                        @keyup.enter="enterUserTag()"  autofocus />
                                    </div>
                                    <!-- Tag End -->

                                </span>

                            </div>
                            <div><br /></div>
                            <div v-if="!showEditMode">joined {{func.convDateTimeFormat(user.dateCreate, 'date')}} <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> last login {{ func.getDateTimeDiff(func.convDateTimeFormat(user.dateLastLogin), new Date()) }}</div>
                            <div v-if="!showEditMode">
                            </div>
                        </td>
                        <td class="align-bottom text-end">
                            <!-- Large screen -->
                            <span class="d-none d-md-block">
                                <table v-if="showEditMode" border="0" align="right">
                                    <tr>
                                        <td class="editLinkBox float-end">
                                            <span  @click="showEditMode = !showEditMode">
                                                <Popper class="popperDark" arrow hover content="Cancel">
                                                    <fa icon="times" class="editLink isLink" style="width: 15px" />
                                                </Popper>
                                            </span>
                                        </td>
                                        <td class="editLinkBox">
                                            <span @click="showEditMode = !showEditMode">
                                                <Popper class="popperDark" arrow hover content="Delete">
                                                    <fa icon="trash" class="editLink isLink" style="width: 15px" />
                                                </Popper>
                                            </span>
                                        </td>
                                        <td class="editLinkBox">
                                            <span @click="showEditMode = !showEditMode">
                                                <Popper class="popperDark" arrow hover content="Update">
                                                    <fa icon="rotate" class="editLink isLink" style="width: 15px" />
                                                </Popper>
                                            </span>
                                        </td>
                                    </tr>
                                </table>

                                <div v-if="!showEditMode">
                                    <span @click="showEditMode = !showEditMode" class="text-grey isLink">
                                        <Popper class="popperDark" arrow hover content="Edit profile">
                                            <fa icon="pen" size="lg" />
                                        </Popper>
                                    </span>
                                </div>
                            </span>
                            <!-- End Large screen -->
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="3">
                            <!-- Small screen -->
                            <div class="d-block d-md-none">
                                <table v-if="showEditMode" border="0" align="right">
                                    <tr>
                                        <td class="editLinkBox float-end">
                                            <span  @click="showEditMode = !showEditMode">
                                                <Popper class="popperDark" arrow hover content="Cancel">
                                                    <fa icon="times" class="editLink isLink" />
                                                </Popper>
                                            </span>
                                        </td>
                                        <td class="editLinkBox">
                                            <span @click="showEditMode = !showEditMode">
                                                <Popper class="popperDark" arrow hover content="Delete">
                                                    <fa icon="trash" class="editLink isLink" />
                                                </Popper>
                                            </span>
                                        </td>
                                        <td class="editLinkBox">
                                            <span @click="showEditMode = !showEditMode">
                                                <Popper class="popperDark" arrow hover content="Update">
                                                    <fa icon="rotate" class="editLink isLink" />
                                                </Popper>
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>   
                            <!-- End Small screen --> 
                        </td>
                    </tr>
                </table>
            </div>
        </div>    

        <div v-if="showEditMode" class="row">

            <div class="col-12 col-lg-6 pb-3">
                <input v-model="firstName" class="form-control" placeholder="First name">
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <input v-model="lastName" class="form-control" placeholder="Last name">
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <input v-model="nickname" type="text" class="form-control" placeholder="Nickname">
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <input v-model="email" type="text" class="form-control" placeholder="Email">
            </div>

        </div>

    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAvatar"
        tabindex="-1"
        aria-labelledby="UplAvatar"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Upload Avatar</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="col-12">New uploaded avatar will replace your current avatar</div> -->
                        <div class="col-12">
                            <file-pond
                                name="file"
                                ref="pondA"
                                label-idle="Drag-n-drop image"
                                v-bind:allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png, image/gif,"
                                :server="avatarConfig" 
                                :files="myAvatars"
                                storeAsFile="true"
                                @init="handleAvatarPondInit"
                                @error="uplAvatarError"
                                @processfile="onprocessAvatar"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <div class="text-center small text-grey">Min. 120 x 120 pixels <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 


</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'
import Tag from '@/components/ProjTag.vue'

// Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'ContactEdit',
    components: { Alert, TopNavigation, Popper, Avatar, FilePond, Tag },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const showEditMode = ref(false)
        const user = ref({})
        const userTag = ref(null)
        const userTagRef = ref(null)
        const showDropdown = ref(false)

        const firstName = ref(null)
        const lastName = ref(null)
        const nickname = ref(null)
        const email = ref(null)
        const title = ref(null)
        const gender = ref(null)
        const gmt = ref(null)
        const plan = ref(null)
        const gmtOpt = ref([])
        const titleOpt = ref([])
        
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const avatarConfig = ref({ 
            "url": "https://signon.my:3002/user/avatar/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const uplAvatarError = (error) => {
            console.info('uplAvatarError', error)
        }

        const onprocessAvatar = (err, file) => {
            // console.info('processfile: err', err, 'file:' , file)
            if (err === null) {
                getProfile()
            }
        }

        const getUser = () => {
            user.value = {
                userId: 'joey1980@gmail.com',
                email: 'joey1980@gmail.com',
                firstName: 'Mei Lee',
                lastName: 'Tan',
                nickname: 'Joey80',
                avatar: 'https://signon.my:3002/user/avatar/U2FsdGVkX189nrR8faiqgPsdnk6bJ7MKa0OO2visL7CO6EtSLm4wR9xOnZVcGPUcdbRP0ndC2iWlxZTgLtFlDk2ilX36NG6T3Vds1L2a3S4hdMO5v0LQ7TXHvsHUHsEMkafCKur5P1KGhrRHiVp8ndNYcB9EPQe1Q2u3A4le1Q2u3A4l',
                isVerified: false,
                title: 'Miss',
                gender: 'F',
                gmt: '+8:00',
                dateCreate: '2022-07-27T07:40:29.000Z',
                dateLastLogin: '2022-07-27T07:40:51.000Z',
                plan: 'Team',
                tags: ['mcis', 'management']
            }

            firstName.value = user.value.firstName
            lastName.value = user.value.lastName
            nickname.value = user.value.nickname
            email.value = user.value.email
            title.value = user.value.title
            gender.value = user.value.gender
            gmt.value = user.value.gmt

        }

        const getGmtOpt = () => {
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1) {
                        gmtOpt.value = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('getGmt | ERR', JSON.stringify(error))
                })
        }

        const getTitleOpt = () => {
            // Title list
            axios.get('/reference/title')
            .then((res) => {
                if (res.data.status === 1) {
                    titleOpt.value = res.data.data
                }
            })
            .catch((error) => {

                console.log('delDoc | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const delTag = (val, idx) => {
            updTag('del', val, idx)
        }

        const updTag = async (opt, val, idx=0) => {
            const tmpTag = user.value.tags

            if (opt === 'add') {
                tmpTag.push(val)

                // mp -  assume added successfully
                if (!user.value.tags.includes(val))
                {
                    user.value.tags.push(val)
                }
                    userTag.value = null 

            } else {
                const tmpTag = tags.value
                tmpTag.splice(idx, 1)
            }

            /* const  p = {
                tags: tmpTag
            }

            axios.put( '/signon/' + route.params.projectId, p
                ).then((res) => {
                    if (res.data.status === 1) {
                        userTag.value = null
                        // projTagOptRef.value.classList.remove('show') For tag dropdown menu purpose

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: opt === 'add' ? "Tag is added." : "Tag is removed."
                        })
                    } 
                })
                .catch((error) => {

                    console.log('updTag | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                }) */
        }

        const enterUserTag = () => {

            if (userTag.value.length > 0) {
                console.info('enterTags', userTag.value)

                if (user.value.tags.includes(userTag.value)) {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Tag already exists.'
                    })
                } else {
                    updTag('add', userTag.value)
                }
            }

        }

        onMounted(() => {
            getUser()
            getGmtOpt()
            getTitleOpt()

            /* let objMdl1 = document.querySelector('#mdlAvatar')

            objMdl1.addEventListener("hide.bs.modal", function (event) {
                console.info('trigger modal close')
                avatarUrl.value = computed({
                    get: () => store.getAvatar.value,
                    set: store.updAvatar(avatar.value.url),
                })
                avatarUrl.value = store.getAvatar.value 
            }) */
        })

        return {
            alert, closeAlert, router, func, store, showEditMode, 
            firstName, lastName, nickname, title, gender, gmt, plan, gmtOpt, titleOpt, genderOpt, email, 
            user, avatarConfig, uplAvatarError, onprocessAvatar, delTag, userTag, userTagRef, enterUserTag, showDropdown,
      
        }
    },
    data () {
        return {
            myAvatars: [],
        }
    },
    handleAvatarPondInit (){
        this.$refs.pondA.getFiles()
    },
}
</script>

<style>
.customLink {
    z-index: 10000; 
    padding-top: 70px; 
    opacity: 0.6;
    visibility: visible;
    background-color: white;
    width: 122px;
}
.editLinkBox {
    width: 50px;
    /* border:1px solid red; */
    text-align: center;
    padding: 5px;
}

.editLink {
    font-size: 18px;
    padding: 8px; 
}

.editLink:hover {
    background-color: #DADADB;
    padding: 8px 10px;
    border-radius: 20px;
}

.nextBoxContainer {
    width: 55%;
    max-width: 835px;
    margin: 25px auto 25px auto;
    border-radius: 10px;
}

.smallBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .nextBoxContainer {
    width: 98%;
  }
}
</style>